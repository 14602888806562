import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.replace-all.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations } from "vuex";
import CryptoJS from "crypto-js";
import Kilt from "@kiltprotocol/sdk-js";
import { u8aToHex } from "@polkadot/util";
import CancelDialog from "@/common/components/Dialog/CancelDialog";
import { cancelOrder } from "@debionetwork/polkadot-provider";
import { queryLastOrderHashByCustomer, queryOrderDetailByOrderID } from "@debionetwork/polkadot-provider";
import { setOrderPaidFee, setOrderPaid } from "@/common/lib/polkadot-provider/command/order";
import { getOrderDetail } from "@/common/lib/api";
import { getDNACollectionProcess } from "@/common/lib/api";
import { errorHandler } from "@/common/lib/error-handler";
import { createOrder } from "@/common/lib/polkadot-provider/command/order";
import { formatUSDTE } from "@/common/lib/price-format.js";
import { processRequest } from "@/common/lib/polkadot-provider/command/service-request";
import PaymentDialog from "@/common/components/Dialog/PaymentDialog";
import Web3 from "web3";
export default {
  name: "PaymentDetailCard",
  components: {
    CancelDialog,
    PaymentDialog
  },
  data: () => ({
    newService: false,
    lastOrder: null,
    detailOrder: null,
    cancelDialog: false,
    isCancelled: false,
    status: "Unpaid",
    labDetail: null,
    orderId: "",
    showAlert: false,
    showError: false,
    isCreated: false,
    success: false,
    loading: false,
    rate: null,
    usdRate: null,
    txWeight: "",
    errorTitle: "",
    errorMsg: "",
    loadingPayment: false,
    fetching: false,
    formatUSDTE
  }),
  async mounted() {
    if (this.$route.name === "customer-request-test-checkout" && !this.dataService) {
      this.toDashboard();
      return;
    }
    if (this.$route.name === "customer-request-test-success") {
      this.success = true;
    }
    if (this.$route.params.id) {
      this.isCreated = true;
      const orderId = this.$route.params.id;
      this.lastOrder = await queryLastOrderHashByCustomer(this.api, this.wallet.address);
      if (this.lastOrder) {
        const detailOrder = await queryOrderDetailByOrderID(this.api, orderId);
        this.detailOrder = detailOrder;
        this.orderId = this.detailOrder.id;
        this.status = this.detailOrder.status;
      }
    }

    // await this.getUsdRate();
    await this.calculateTxWeight();
  },
  computed: {
    ...mapState({
      api: state => state.substrate.api,
      wallet: state => state.substrate.wallet,
      mnemonicData: state => state.substrate.mnemonicData,
      dataService: state => state.testRequest.products,
      metamaskWalletAddress: state => state.metamask.metamaskWalletAddress,
      stakingData: state => state.lab.stakingData,
      lastEventData: state => state.substrate.lastEventData,
      usdtBalance: state => state.substrate.usdtBalance,
      polkadotWallet: state => state.substrate.polkadotWallet
    })
  },
  watch: {
    async lastEventData(event) {
      const dataEvent = JSON.parse(event.data.toString());
      const orderId = dataEvent[0].id;
      this.status = dataEvent.status;
      if (!event) return;
      if (event.method === "OrderCancelled" && this.isCancelled) {
        this.loading = false;
        this.$router.push({
          name: "customer-request-test-canceled",
          params: {
            hash: this.$route.params.id || this.$route.params.hash
          }
        });
      }
      if (event.method === "OrderCreated") {
        await this.setPaid(orderId);
      }
      if (event.method === "OrderPaid") {
        if (dataEvent[0].orderFlow === "RequestTest") {
          this.loadingPayment = false;
          this.success = true;
          this.$router.push({
            name: "customer-request-test-success",
            params: {
              id: dataEvent[0].id
            }
          });
          return;
        }
        await this.processRequestService(dataEvent[0]);
      }
      if (event.method === "ServiceRequestUpdated") this.$router.push({
        name: "customer-request-test-success"
      });
    }

    // dataService(val) {
    //   if (val) this.getUsdRate();
    // }
  },

  methods: {
    ...mapMutations({
      setProductsToRequest: "testRequest/SET_PRODUCTS"
    }),
    async calculateTxWeight() {
      this.txWeight = "Calculating...";
      this.lastOrder = await queryLastOrderHashByCustomer(this.api, this.wallet.address);
      const txWeight = await setOrderPaidFee(this.api, this.wallet, this.dataService.serviceId);
      this.txWeight = Web3.utils.fromWei(String(txWeight.partialFee), "ether");
    },
    async processRequestService(event) {
      await processRequest(this.api, this.wallet, this.stakingData.hash, event.id);
    },
    toPaymentHistory() {
      this.$router.push({
        name: "customer-payment-history"
      });
    },
    async onSubmit() {
      this.loadingPayment = true;
      try {
        const balance = this.usdtBalance;
        if (Number(balance) - 0.1 <= Number(this.dataService.totalPrice.replaceAll(",", ""))) {
          this.loadingPayment = false;
          this.showError = true;
          const error = await errorHandler("Insufficient Balance");
          this.error = error.message;
          this.errorTitle = error.title;
          this.errorMsg = error.message;
          return;
        }
        if (this.isCreated) {
          this.lastOrder = await queryLastOrderHashByCustomer(this.api, this.wallet.address);
          await setOrderPaid(this.api, this.wallet, this.lastOrder);
          return;
        }
        await this.createOrder();
      } catch (err) {
        this.loadingPayment = false;
        const error = await errorHandler(err.message);
        this.showError = true;
        this.errorTitle = error.title;
        this.errorMsg = error.message;
      }
    },
    async setPaid(id) {
      await setOrderPaid(this.api, this.wallet, id);
    },
    async createOrder() {
      const assetId = await this.getAssetId(this.dataService.currency === "USDTE" ? "USDT.e" : this.dataService.currency);
      const customerBoxPublicKey = await this.getCustomerPublicKey();
      const indexPrice = 0;
      await createOrder(this.api, this.wallet, this.dataService.serviceId, indexPrice, customerBoxPublicKey, this.dataService.serviceFlow, assetId);
    },
    async getAssetId(currency) {
      let assetId = 0;
      const wallet = this.polkadotWallet.find(wallet => {
        var _wallet$currency;
        return (wallet === null || wallet === void 0 ? void 0 : (_wallet$currency = wallet.currency) === null || _wallet$currency === void 0 ? void 0 : _wallet$currency.toUpperCase()) === (currency === null || currency === void 0 ? void 0 : currency.toUpperCase());
      });
      assetId = wallet.id;
      return assetId;
    },
    getCustomerPublicKey() {
      const identity = Kilt.Identity.buildFromMnemonic(this.mnemonicData.toString(CryptoJS.enc.Utf8));
      this.publicKey = u8aToHex(identity.boxKeyPair.publicKey);
      this.secretKey = u8aToHex(identity.boxKeyPair.secretKey);
      return u8aToHex(identity.boxKeyPair.publicKey);
    },
    async toInstruction(val) {
      const description = this.dataService.longDescription.split("||");
      if (description.length > 1) {
        window.open(description[1], "_blank");
        return;
      }
      const dnaCollectionProcess = await getDNACollectionProcess();
      const link = dnaCollectionProcess.filter(e => e.collectionProcess === val)[0].link;
      window.open(link, "_blank");
    },
    showCancelConfirmation() {
      if (!this.isCreated) {
        this.toDashboard();
        return;
      }
      this.cancelDialog = true;
    },
    async setCancelled() {
      this.loading = true;
      this.isCancelled = true;
      await cancelOrder(this.api, this.wallet, this.$route.params.id);
    },
    toDashboard() {
      this.$router.push({
        name: "customer-dashboard"
      });
    },
    async getDataService() {
      const data = await getOrderDetail(this.$route.params.id);
      if (data.status !== "Unpaid") {
        this.$router.push({
          name: "customer-payment-history"
        });
      }
      const service = {
        serviceId: data.service_id,
        serviceName: data.service_info.name,
        serviceRate: 0,
        serviceImage: data.service_info.image,
        serviceCategory: data.service_info.category,
        serviceDescription: data.service_info.description,
        labName: data.lab_info.name,
        labId: data.seller_id,
        labImage: data.lab_info.profile_image,
        labRate: 0,
        labAddress: data.lab_info.address,
        price: data.service_info.prices_by_currency[0].total_price.replaceAll(",", ""),
        detailPrice: data.service_info.prices_by_currency[0],
        currency: data.service_info.prices_by_currency[0].currency,
        city: data.lab_info.city,
        country: data.lab_info.country,
        region: data.lab_info.region,
        countRateLab: 0,
        countServiceRate: 0,
        duration: data.service_info.expected_duration.duration,
        durationType: data.service_info.expected_duration.durationType,
        verificationStatus: "Verified",
        indexPrice: 0,
        dnaCollectionProcess: data.service_info.dna_collection_process
      };
      this.setProductsToRequest(service);
    }
  }
};